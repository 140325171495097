/* Place any GLOBAL hover, active, visited, focused, cursor, overflow, etc styles in here */

.c-initial {
  cursor: initial;
}

.c-pointer {
  cursor: pointer;
}

.c-move {
  cursor: move;
}

.no-hover:hover {
  cursor: initial !important;
  background: initial !important;
}

a.clear-styles {

  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    padding: inherit;
    vertical-align: inherit;
  }
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}