// Place any GLOBAL classes for UI element effects and UI elements in this file

@use './color-palette.scss' as palette;
@use './layout.scss' as layout;
@use '../sass/_variables.scss' as variables;

/* UI Element Effects */
.no-highlight {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radius {
  border-radius: 0px !important;
}

.text-shadow {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
  cursor: pointer;
}

.shadow {
  -webkit-box-shadow: 0px 0px 14px -2px rgba(50, 50, 50, 0.2) !important;
  box-shadow: 0px 0px 14px -2px rgba(50, 50, 50, 0.2) !important;
}

.shade {
  background: rgba(0, 0, 0, 0.15);

  &-rgb {
    background: #d8d8d8;
  }
}

.shade-lighter {
  background: rgba(0, 0, 0, 0.02);

  &-rgb {
    background: #f9f9f9;
  }
}

.full-shade {
  @extend .middle;
  background: rgba(0, 0, 0, 0.05);
  z-index: 1000;
}

.light-green-border {
  border: 1px solid variables.$matLightGreen200;
}

/* UI Elements */
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flag {
  border: 1.5px solid;
  border-radius: 15px;
  padding: 0.21rem 0.5rem;
  width: fit-content;
  font-weight: 500;
  font-size: variables.$body-sm;
  line-height: variables.$body-sm-lh;

  &__blue {
    border-color: variables.$primary;
    color: variables.$primary;
  }

  &__green {
    border-color: variables.$secondary;
    color: variables.$matLightGreen500;
  }

  &__red {
    border-color: variables.$warn;
    color: variables.$warn;
  }
}

.msg-purple {
  border-radius: 10px;
  // padding: 10px;
  // background-color: variables.$m;
  color: variables.$matGray700;
}

.modal-close-icon-btn {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  border: none;
  background-color: variables.$primary;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay-panel {
  @extend .radius;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  display: block;
}

.floating-panel {
  @extend .shadow;
  background-color: white;
}

.hint {
  color: variables.$matGray600;
}

.NgxEditor__Wrapper {
  border: 1px solid variables.$border-color !important;
}

/* 
  Not sure what the below styles are doing or if they are needed.
  Possibly remove at a later time.
*/

.avatar-icon {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

span.avatar-icon {
  display: inline-block;
  text-align: center;
  padding-top: 5px;
  width: 36px;
  height: 36px;
  font-size: 16px;
  line-height: 1.7em;
}

.avatar-icon-flexible {
  display: inline-block;
  text-align: center;
  height: 2em;
  line-height: 2.1em;
  border-radius: 1em;
  padding: 0 0.8em;
}

/* Inputs */
input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }

  -moz-appearance: textfield;
}

/* Tables */
table {
  td,
  th {
    &.no-wrap {
      width: 0%;
    }

    &.ellipsis {
      max-width: 1px;
    }
  }
}

table.clear-user-agent-styles {
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: inherit;
    text-align: left;
    font-weight: inherit;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
  }
}

// currently used in pr-email-edit
[contenteditable] {
  white-space: pre-wrap;
  outline: 0;
  border-radius: var(--mdc-shape-small, 4px);

  &[disabled='true'] {
    pointer-events: none;
    background: #f9f9f9;
  }

  &:empty::before {
    content: attr(placeholder);
    color: #9d9d9d;
  }
}

.icon-url-disabled {
  pointer-events: none;

  mat-icon {
    color: gray !important;
  }
}
