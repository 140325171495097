@use '../../../node_modules/@angular/material' as mat;
@use '../sass/_variables.scss' as variables;

.body-sm {
  font-size: variables.$body-sm;
  line-height: variables.$body-sm-lh;
}

.body-md {
  font-size: variables.$body-md;
  line-height: variables.$body-md-lh;
}

.body-lg {
  font-size: variables.$body-lg;
  line-height: variables.$body-lg-lh;
}

.body-xl {
  font-size: variables.$body-xl;
  line-height: variables.$body-xl-lh;
}

$mat-typography: mat.define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  /* font-size, line-height, font-weight */ $subtitle-1: mat.define-typography-level(variables.$body-xl, variables.$body-xl-lh, 500),
  $body-1: mat.define-typography-level(variables.$body-lg, variables.$body-md-lh, 400),
  $subtitle-2: mat.define-typography-level(variables.$body-md, variables.$body-md-lh, 400),
  $body-2: mat.define-typography-level(variables.$body-md, variables.$body-md-lh, 400),
  $caption: mat.define-typography-level(variables.$body-sm, variables.$body-sm, 400),
  $button: mat.define-typography-level(variables.$body-md, variables.$body-md-lh, 500)
);

@include mat.all-component-typographies($mat-typography);

body {
  $mat-equal-body-line-height: map-merge(
    $mat-typography,
    mat.define-typography-config(
      $body-1: mat.define-typography-level(variables.$body-md, variables.$body-md, 400),
      $body-2: mat.define-typography-level(variables.$body-md, variables.$body-md, 400)
    )
  );

  /* setting font size for mat-chip, mat-form-field, mat-menu, mat-option, and mat-select */
  @include mat.chips-typography($mat-equal-body-line-height);
  @include mat.form-field-typography($mat-equal-body-line-height);
  @include mat.menu-typography($mat-equal-body-line-height);
  @include mat.option-typography($mat-equal-body-line-height);
  @include mat.select-typography($mat-equal-body-line-height);
}
