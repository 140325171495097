// ONLY place global stylesheet & sass uses in here or global html selector styles

@use '../node_modules/@angular/material' as mat;

/* Font awesome icons */
@use './assets/sass/font-awesome';

/* Sass */
@use './assets/sass/variables' as variables;
@use './assets/sass/mixins/mixins';

/* Custom stylesheets */
@use './assets/styles/color-palette';
@use './assets/styles/layout';
@use './assets/styles/states';

// Bootstrap Sass variable overrides MUST be declared BEFORE Bootstrap is imported
$primary: #3f51b5;
$secondary: #8bc34a;
$warn: #dc3545;
$border-color: #bdbdbd;

@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap/scss/bootstrap-utilities';
@import '../node_modules/bootstrap/scss/_root';

// We use @import instead of @use to ensure access to Bootstrap utility classes
// within material-overrides.scss and prorank-custom-styles.scss.
@import './assets/styles/material-overrides';
@import './assets/styles/prorank-custom-styles';

@include mat.core();

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: variables.$base-font;
}

html.iframe {
  &,
  body {
    font-size: 12px;
  }

  .iframe-hidden {
    display: none !important;
  }
}

html:not(.iframe) {
  .iframe-only {
    display: none !important;
  }
}

body {
  margin: 0;
}

html.hidden > body {
  display: none;
}

// Ngx chart style overrides
[ngx-charts-axis-label] {
  font-weight: 500;
}

.custom-height .mat-mdc-text-field-wrapper {
  width: 100%;
  align-items: center;
}

/* the only way to override material's styling for datepicker in candidate questionnaire */
/* remove when we determine a better method for datepickers */
.questionnaire-row {
  .mat-datepicker-toggle {
    height: 2rem !important;
  }
  .mat-datepicker-toggle-default-icon {
    width: 1.5rem !important;
  }
  .mat-mdc-focus-indicator,
  .mat-mdc-button-touch-target,
  .mat-mdc-button-ripple,
  .mat-mdc-button-persistent-ripple {
    display: none !important;
  }

  .mat-mdc-icon-button {
    padding: 0 !important;
    height: 2.14rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
