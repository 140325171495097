// This file sets our color palette, tells material what colors to use for our theme, and creates dynamic color classes

@use '../../../node_modules/@angular/material' as mat;

@use '../sass/_variables.scss' as variables;

@use './typography.scss' as typography;

@include mat.core();

$mat-primary: mat.define-palette(mat.$indigo-palette);
$mat-accent: mat.define-palette(mat.$light-green-palette);
$mat-warn: mat.define-palette(mat.$red-palette);

$mat-colors: (
  'light-blue': mat.$light-blue-palette,
  'green': mat.$green-palette,
  'yellow': mat.$yellow-palette,
  'orange': mat.$orange-palette,
  'gray': mat.$gray-palette,
  'blue': mat.$blue-palette,
  'primary': $mat-primary,
  'accent': $mat-accent,
  'warn': $mat-warn,
  '1': mat.$blue-palette,
  '2': mat.$green-palette,
  '3': mat.$red-palette,
  '4': mat.$yellow-palette,
  '5': mat.$purple-palette,
  '6': mat.$teal-palette,
  '7': mat.$pink-palette,
  '8': mat.$orange-palette,
  '9': mat.$cyan-palette,
  '10': mat.$indigo-palette,
  '11': mat.$deep-purple-palette,
  '12': mat.$light-blue-palette,
  '13': mat.$light-green-palette,
  '14': mat.$lime-palette,
  '15': mat.$amber-palette,
  '16': mat.$deep-orange-palette
);

$mat-hue: (
  '': 500,
  '-light': 200,
  '-dark': 700,
  '-50': 50,
  '-100': 100,
  '-200': 200,
  '-300': 300,
  '-400': 400,
  '-500': 500,
  '-600': 600,
  '-700': 700,
  '-800': 800
);

:root {
  @each $color, $mat-color in $mat-colors {
    @each $suffix, $hue in $mat-hue {
      $variations: (
        '': mat.get-color-from-palette($mat-color, $hue),
        '-contrast': mat.get-contrast-color-from-palette($mat-color, $hue)
      );

      @each $variation, $hex in $variations {
        --mat-color-#{$color}#{$suffix}#{$variation}: #{$hex};
      }
    }
  }
}

@each $color, $mat-color in $mat-colors {
  @each $suffix, $hue in $mat-hue {
    $variations: (
      '': mat.get-color-from-palette($mat-color, $hue),
      '-contrast': mat.get-contrast-color-from-palette($mat-color, $hue)
    );

    /* Creates classes based on provided color and hue: 
      mat-color-, mat-background-, mat-border-, mat-caret-, mat-placeholder-, mat-progress-fill-, mat-progress-
    */

    @each $variation, $hex in $variations {
      .mat-color-#{$color} {
        &#{$suffix}#{$variation} {
          color: #{$hex} !important;
        }
      }

      .mat-background-#{$color} {
        &#{$suffix}#{$variation} {
          background-color: #{$hex} !important;
        }
      }

      .mat-border-#{$color} {
        &,
        &.border {
          &#{$suffix}#{$variation} {
            border-color: #{$hex} !important;
          }
        }
      }

      .mat-caret-#{$color} {
        &#{$suffix}#{$variation} {
          caret-color: #{$hex} !important;
        }
      }

      .mat-placeholder-#{$color} {
        color: #{$hex} !important;

        &#{$suffix}#{$variation} {
          input::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #{$hex} !important;
            opacity: 1;
            /* Firefox */
          }

          input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #{$hex} !important;
          }

          input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #{$hex} !important;
          }
        }
      }

      .mat-progress-fill-#{$color} {
        &#{$suffix}#{$variation} {
          .mat-progress-bar-fill::after {
            background-color: #{$hex};
          }
        }
      }

      .mat-progress-#{$color} {
        &#{$suffix}#{$variation} {
          .mat-progress-bar-buffer {
            background-color: #{$hex};
          }
        }
      }

      // currently used in nlp-entity-recognizer
      // .nlp-tag-#{$color} {
      //   @extend .text-center, .d-inline-block, .rounded, .px-1, .mb-2, .border, .no-highlight;
      //   @extend .mat-background-gray-100, .mat-border-#{$color}-700;

      //   .label {
      //     @extend .fw-bold, .text-uppercase, .d-block;
      //     @extend .mat-color-#{$color}-700;

      //     font-size: 80%;
      //     letter-spacing: 0.1em;
      //   }
      // }
    }
  }
}

$mat-theme: mat.define-light-theme(
  (
    color: (
      primary: $mat-primary,
      accent: $mat-accent,
      warn: $mat-warn
    ),
    density: -1,
    typography: typography.$mat-typography
  )
);

@include mat.all-component-themes($mat-theme);
