/* Place global layout, sizing and positioning classes in here
  try to use bootstrap sizing, margin, padding, flex, etc classes as much as possible instead of creating new classes in here 
*/

.middle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-wrap {
  white-space: nowrap;
}

.flex-0-grow {
  flex: 1 1 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.spacer {
  flex: 1 1 auto;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.w-0 {
  width: 0;
}

.h-0 {
  height: 0;
}

.mw-400px {
  max-width: 400px !important;
}

.mh-300px {
  max-height: 300px !important;
}