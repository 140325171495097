/* Bootstrap palette overrides */
$primary: #3f51b5;
$secondary: #8bc34a;
$warn: #dc3545;
$border-color: #bdbdbd; // material gray 400
$primaryFont: #030303;

/* GRAY TONES */
// mat-background-gray-{{hue here}}, mat-color-gray-{{hue here}}, mat-border-gray-{{hue here}}

// input background color, application background color
$matGray50: #fafafa;

// hover and highlight gray
$matGray100: #f5f5f5;

// expansion panel body bkgd
$matGray200: #f2f2f2;

// disabled input bkgd
$matGray300: #e0e0e0;

$matGray400: #bdbdbd;

// disabled button text, gray icons, hint (gray) text
$matGray600: #757575;

// disabled text, other dark gray text
$matGray700: #616161;

$matGray900: #808080;

/* PRIMARY COLORS */

// indigo (primary)
$matIndigo50: #e8eaf6;
$matIndigo200: #9fa8da;
$matIndigo400: #153366;
$matIndigo500: #3f51b5;

// light green (secondary)
$matLightGreen500: #8bc34a;
$matLightGreen200: #c5e1a5;
$matLightGreen50: #f1f8e9;

/* ACCENT COLORS */
// green
$matGreen200: #a5d6a7;
$matGreen500: #4caf50;
$matGreen800: #2e7d32;

// blue
$matBlue700: #1976d2;
$matBlue300: #64b5f6;
$matBlue100: #bbdefb;

// purple
$matPurple500: #9c27b0;
$matPurple300: #ba68c8;
$matPurple100: #e1bee7;

/* ALERTING */

// RED: bg-danger, border-danger, text-danger classes
// YELLOW: bg-warning, border-warning, text-warning classes

/* TEXT */
$base-font: 14px;
$body-sm: 0.85rem;
$body-sm-lh: 0.9rem;
$body-md: 1rem;
$body-md-lh: 1.3rem;
$body-lg: 1.15rem;
$body-lg-lh: 1.3rem;
$body-xl: 1.5rem;
$body-xl-lh: 1.6rem;
