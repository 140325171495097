/* Place any GLOBAL Bootstrap or Material style overrides in here
  if a style needs overrode only in a specific component, put the override in that component's scss file
*/
@use '../../../node_modules/@angular/material' as mat;
@use './color-palette.scss' as palette;
@use '../sass/variables' as variables;
@use './prorank-custom-styles.scss';

.spinner-border {
  border-color: variables.$secondary;
}
/* BOOTSTRAP FORMS & INPUTS */

/* must be applied to all <input /> tags to ensure consistent styling */
.form-control {
  padding: 0.5rem 0.625rem;
  height: 2.14rem;
  font-size: variables.$body-md;
  color: black;
  border: 1px solid variables.$matGray400;
  border-radius: 4px;

  &__lg {
    padding: 0.5rem 16px;
    height: 44px;
    font-size: variables.$body-lg;
  }

  &__prefix {
    padding-left: 40px;
    // padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  }

  &__suffix {
    border-right: 0;
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
  }

  &::placeholder {
    color: variables.$matGray600;
  }

  &:focus {
    color: variables.$primaryFont;
    border: 1px solid variables.$primary;
    box-shadow: none;
  }
}

/* add to <label> tags if the input involved has a label */
.form-label {
  font-weight: 500;
  font-size: variables.$body-md;
}

/* apply to <select> tags instead of form-control */
.form-select {
  background-color: #fff !important;
  padding: 0 30px 0 10px;
  height: 32px;
  font-size: variables.$body-md;
  color: black;
  border: 1px solid variables.$matGray400;
  border-radius:6px;
}

.input-with-icon {
  position: relative;

  .mat-icon {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-49%);
    z-index: 2;
    width: 15px;
    height: 15px;
    font-size: 15px;
    color: variables.$matGray600;
  }
}

.input-group {
  .form-control:focus {
    border-color: variables.$border-color;
  }
}

/* apply to the text or icon inside an input-group */
.input-group-text {
  color: variables.$primary;
  // height: 44px;
  height: 2.14rem;
  border-radius: 0.14rem;
  font-size: variables.$body-lg;
  align-self: center;
  background-color: white;
  .spinner-border {
    width: variables.$body-lg;
    height: variables.$body-lg;
  }

  &__multi-input-label {
    background-color: none;
    align-self: center;
    padding: 0.5rem;
    font-weight: 500;
  }

  &__prefix {
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    // border-top: 1px solid variables.$matGray400;
    // border-bottom: 1px solid variables.$matGray400;
    // border-left: 1px solid variables.$matGray400;
    // border-right: 0px solid transparent !important;
  }

  &__suffix {
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    // border-top: 1px solid variables.$matGray400;
    // border-bottom: 1px solid variables.$matGray400;
    // border-right: 1px solid variables.$matGray400;
    // border-left: 0px solid transparent !important;
  }
  
}

.form-check-input {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid variables.$matGray600;

  &:focus {
    box-shadow: none !important;
  }
}

.form-check-label {
  color: black;
  font-size: variables.$body-md;
}

.form-check-label:disabled {
  color: variables.$matGray600;
}

/* everything below this comment is for Material classes
  (todo: replace with Bootstrap)
*/

/* buttons */

/* bordered buttons, no fill */

.mat-mdc-outlined-button {
  border-width: 1.5px !important;
  border-radius: 4px !important;
  font-size: variables.$body-md !important;
  line-height: 1 !important;
}

/* solid color buttons */
.mat-mdc-unelevated-button {
  border-radius: 4px !important;
  font-size: variables.$body-md !important;
  line-height: 1 !important;
}

.mdc-button__lg {
  height: 44px !important;
  font-size: variables.$body-lg !important;
  font-weight: 400 !important;
  line-height: 1 !important;
}

.mat-mdc-unelevated-button {
  font-size: variables.$body-lg !important;
  font-weight: 400 !important;
  line-height: 1 !important;
}

.btn-with-caret {
  padding-right: 50px !important;
  position: relative;

  &__green {
    background-color: variables.$secondary !important;

    &::after {
      border-left: 1.5px solid #fff !important;
      color: #fff;
    }

    &.mat-mdc-outlined-button:not(:disabled) {
      border-color: variables.$matGray400 !important;

      mat-icon {
        color: #fff;
      }
    }

    .mdc-button__label {
      color: #fff;
    }
  }
}

.btn-with-caret::after {
  content: 'expand_more';
  font-family: 'Material Icons';
  position: absolute;
  right: 0;
  top: 50%;
  width: 35px;
  font-size: 25px;
  height: 100%;
  transform: translateY(-50%);
  border-left: 1.5px solid variables.$primary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: variables.$matGray400 !important;
  background-color: white;
}

.mat-mdc-outlined-button.mat-warn:not(:disabled) {
  border-color: variables.$warn !important;
}

.warn-button.mat-mdc-outlined-button:not(:disabled) {
  border-color: variables.$warn !important;
}

.green-button.mat-mdc-outlined-button:not(:disabled) {
  border-color: variables.$matLightGreen500 !important;
}

.mat-mdc-raised-button:disabled {
  color: variables.$matGray600 !important;
}

.mat-mdc-outlined-button:disabled {
  color: variables.$matGray600 !important;
  border-color: variables.$matGray600 !important;
  background-color: white;
}

.mat-mdc-icon-button {
  &.default-size {
    width: initial;
    height: initial;
    line-height: initial;
    padding: 0;
  }

  &.no-hover-effect {
    .mat-button-toggle-focus-overlay,
    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-ripple,
    .mat-mdc-button-touch-target {
      display: none;
    }
  }
}

/* Chips */
.mat-mdc-chip {
  .mat-small {
    @extend .mat-color-gray-dark;
    font-size: 65%;
    line-height: 65%;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-top: 0.1em;
  }

  &.high-quality {
    border-bottom: 3px solid #{mat.get-color-from-palette(mat.$light-green-palette, 800)} !important;
  }

  &.low-quality {
    border-bottom: 3px solid #{mat.get-color-from-palette(mat.$red-palette, 800)} !important;
  }
}

/* tells all mat-chips to display ... when the text of the chip is larger than the chip itself
  sets a max width on all mat-chips
*/
.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}

/* Divider */
.mat-divider.mat-divider-horizontal {
  border-color: variables.$border-color;
}

/* Expansion Panel */
.mat-accordion {
  &.no-spacing .mat-expansion-panel-spacing {
    @extend .m-0;
  }

  &.no-body-padding .mat-expansion-panel-body {
    @extend .p-0;
  }
}

/* Form Fields */

// hides the bottom border that material adds to mat-form-fields by default
.mat-mdc-form-field {
  .mdc-line-ripple {
    @extend .d-none;
  }
}

// sets the border and color style for all mat-select, input and textarea tags inside a mat-form-field tag
.mdc-text-field:not(.mdc-text-field--disabled) {
  border: 1px solid variables.$border-color;
  background-color: variables.$matGray50 !important;
  border-radius: 2px !important;
  padding-left: 10px;
}

// sets the disabled state border and color style for all mat-select, input and textarea tags inside a mat-form-field tag
.mdc-text-field--disabled {
  color: variables.$matGray700 !important;
  border: 1px solid variables.$border-color;
  background-color: variables.$matGray300 !important;
  border-radius: 2px !important;
}

// set the color of the prefix icon inside a mat-form-field ie. the search icon at the start of a search bar
.mat-mdc-form-field-icon-prefix {
  color: variables.$matGray600;
}

/* applies to all mat-form-fields in the application 
  that also have the specified class ie. no-underline
*/

// shrink mat-form-field vertically if shrink-form-field is specified
.mat-mdc-form-field.shrink-form-field {
  .mat-mdc-form-field-infix {
    padding: 8px 0 !important;
    min-height: initial !important;
    border: 0;
  }
}

// hide the bar attached to the bottom of mat-form-fields if no-underline is specified
.mat-mdc-form-field.no-underline {
  .mat-mdc-form-field-subscript-wrapper {
    @extend .d-none;
  }

  .mdc-line-ripple {
    @extend .d-none;
  }
}

/* can probably remove/update these sometime */
.mat-mdc-form-field.no-bottom-padding {
  .mat-mdc-form-field-subscript-wrapper {
    @extend .h-0;
  }
}

.mat-mdc-form-field.fix-border {
  .mdc-notched-outline__notch {
    @extend .d-none;
  }
}

.mat-mdc-form-field.fix-infix {
  .mat-mdc-form-field-infix {
    padding: 16px 0 !important;
    min-height: initial !important;
    border: 0;
  }
}

.mat-mdc-form-field.fix-infix-sm {
  .mat-mdc-form-field-infix {
    padding: 8px 0 !important;
    min-height: initial !important;
    border: 0;
  }
}

/* Icons */
.mat-icon {
  &.medium {
    font-size: 48px;
    height: 48px;
    width: 48px;
  }

  &.large {
    font-size: 72px;
    height: 72px;
    width: 72px;
  }

  &.fa,
  &.fab {
    font-size: 24px;
  }
}

/* Inputs */
.mat-mdc-option.flex-row {
  & > span {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
}

/* List item */
.mat-mdc-list-item {
  .mdc-list-item__content {
    overflow: hidden;
  }

  span {
    font-size: 14px;
    text-overflow: ellipsis;
  }
}

/* Menu */
.mat-mdc-menu-panel {
  & > .mat-mdc-menu-content > :first-child:not(:last-child) {
    margin-top: -8px;
  }

  & > .mat-mdc-menu-content > :last-child:not(:first-child) {
    margin-bottom: -8px;
  }
}

/* Snackbar */

// when open dialog and snackbar at the same time - make sure that snackbar is always on top
.cdk-global-overlay-wrapper:has(> .cdk-overlay-pane > mat-snack-bar-container) {
  z-index: 1200;
}

// dialog
.mdc-dialog__title::before {
  display: none !important;
}

/* Table */
.mat-mdc-row:hover,
.hover-row:hover {
  @extend .mat-background-gray-100;
}

.hover-row:not(:hover) .hover-only {
  display: none;
}

/* Tabs */
.mat-mdc-tab .mdc-tab__text-label {
  font-size: 1rem;
  text-transform: uppercase;
}

.mat-mdc-tab-labels {
  padding: 0 0.714rem;
  height: 2.43rem;
}

.mat-mdc-tab.mdc-tab {
  margin-right: 2.14rem;
  padding: 0;
  min-width: 1rem;
  height: 2.43rem !important;
}

// blue and white box tabs, currently used in advanced search modal
.blueBoxTabs {
  .mat-mdc-tab-group .mat-mdc-tab-header {
    box-shadow: none !important;
  }
  .mat-mdc-tab-labels {
    padding: 0 14px;
    height: 3.4rem !important;
  }

  .mat-mdc-tab.mdc-tab {
    margin-right: 0;
    background-color: white;
    color: variables.$primary;
    border: 1px solid variables.$primary;
    height: 3.4rem !important;
  
    &.mdc-tab-indicator--active {
      background-color: variables.$primary;
      color: white;
    }
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: white;
    font-size: medium !important;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
    color: variables.$primary;
    font-size: medium !important;
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    background-color: #fff;
  }
  &.split-tabs-content {
    @extend .flex-column;

    & > .mat-mdc-tab-body-wrapper {
      @extend .flex-grow-1;
      background-color: #fff;
      // min-height: 200px;
    }

    & > .mdc-tab__text-label {
      font-size: xx-small;
    }
  }

  &.h-100 {
    & > .mat-mdc-tab-body-wrapper {
      @extend .h-100;
    }
  }
}

.mat-mdc-tab-nav-bar {
  &.split-tabs-content {
    @extend .mx-3, .mt-3, .mb-0, .px-4, .pt-4, .pb-0, .floating-panel;
  }
}

/* Other */
[dense] {
  @include mat.all-component-densities(
    (
      density: -2
    )
  );
}

// Mat tab overrides

.mat-mdc-tab-group {
  &.split-tabs-content-2 {
    & > .mat-mdc-tab-header {
      @extend .mx-3, .mt-3, .mb-0, .px-0, .pt-0, .pb-0, .floating-panel;
    }
  }
}

// styles for multi select
.mat-multi-select.mat-mdc-form-field {
  .mat-mdc-form-field-infix {
    min-height: 2rem !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 0.2143rem !important;
    padding-bottom: 0px !important;
  }

  .mat-mdc-text-field-wrapper {
    padding-right: 11px !important;
    border-radius: 0.14rem !important;
    border-color: #bdbdbd !important;
  }

  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      width: 16px;
      height: 12px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");

      svg {
        display: none;
      }
    }
  }
}

.mat-mdc-option.mat-mdc-option.mdc-list-item {
  height: auto !important;
  min-height: 42px !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
  padding: 0 0.71rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px !important;
  font-size: 0.85rem;
  font-weight: 400;
}

// expansion panel
.mat-expansion-panel {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
  border-radius: 0;
  box-shadow: none;
}

.remove-scroll {
  .mat-drawer-content {
    overflow: hidden !important;
  }
}

.mat-mdc-list-option .mdc-checkbox {
  width: 16px !important;
  height: 16px !important;
}

.mat-mdc-list-option .mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid variables.$matGray600 !important;
  border-radius: 2px;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff !important;
  width: 12px !important;
  margin-top: 2px !important;
  margin-left: 2px !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border: transparent !important;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 34px !important;
}

.mat-mdc-list-base {
  padding: 0 !important;
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: 9px !important;
}

.mdc-list-item.mdc-list-item--with-one-line {
  height: 34px !important;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  padding: 0 20px !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  background-color:  variables.$primary !important;
}